<template>
  <div>
    <v-tabs class="tabs-link bb-gray flex-grow-0">
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.slug"
        :ripple="false"
        class="px-0 mx-4 fz-14 fw-600 hover-none"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'OrderPage',
  data() {
    return {
      tabs: [
        {
          name: 'Orders Table',
          slug: '/orders/table'
        },
        {
          name: 'Projects Table',
          slug: '/orders/projects'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
